import { useKycApi } from "../api/useKycApi";
import { useKycStore } from "@/modules/store/useKycStore.ts";
import { KycRequest } from "../types/kyc.ts";
import { STATUSCODE } from "@/modules/types/statusCode.ts";
import useMessage from "./useMessage.ts";
import useRouterUtil from "./useRouterUtil.ts";
import useSms from "./useSms.ts";

export default function useKyc() {
  const { getKycAuthInfo, fetchKycAuth } = useKycApi();
  const {
    setKycAuth,
    setKycVerifies,
    setBankAccount,
    setIdNameAndNumber,
    setBankAccountImg,
    setIdFrontImg,
    setIdBackImg,
    setCanUploadBankAccountImg,
    setCanUploadIdCardImg
  } = useKycStore();
  const { 
    kycAuth, 
    kycVerifies, 
    kycAuthForm, 
    kycStatus, 
    canUploadBankAccountImg, 
    canUploadIdCardImg 
  } = storeToRefs(useKycStore());
  const { showMessage } = useMessage();
  const { routerPush } = useRouterUtil();
  const { smsVerify } = useSms(false); //只取方法，不需要啟動計時器

  async function initKyc() {
    try {
      const { data } = await getKycAuthInfo();
      if (data.data.kyc_auth) setKycAuth(data.data.kyc_auth);
      if(data.data.verifies) setKycVerifies(data.data.verifies)
    } catch (err) {
      setKycVerifies({ req_failed: "true" });
    }
  }
  async function submitKyc() {
    if(!check()) return
    await handleSmsVerify()
    const {
      id_front_img,
      id_back_img,
      bank_account_img,
      bank_account,
      name,
      id_number,
      bank_code,
    } = kycAuthForm.value;
    const requestBody: KycRequest = {
      image_ids: [id_front_img?.id!, id_back_img?.id!, bank_account_img?.id!],
      id_number,
      name,
      bank_account,
      bank_code,
    };
    const { data } = await fetchKycAuth(requestBody);
    setKycAuth(data.data);
    showMessage({ message: "資料上傳成功!", type: "success" });
    routerPush("/myCenter/userInfo");
  }
  async function handleSmsVerify() {
    if (kycVerifies.value?.phone === STATUSCODE.approved) return;
    if (!kycAuthForm.value.phone)
      return showMessage({ message: "請填寫手機號碼", type: "warning" });
    if (!kycAuthForm.value.sms_code)
      return showMessage({ message: "請填寫簡訊認證碼", type: "warning" });
    return smsVerify({
      phone: kycAuthForm.value.phone,
      opt: kycAuthForm.value.sms_code,
    });
  }
  function check() {
    const {
      id_front_img,
      id_back_img,
      bank_account_img,
      bank_account,
      name,
      id_number,
      bank_code,
    } = kycAuthForm.value;
    let checked = true;
    let message = "缺少:";
    if (!id_front_img) {
      message += "身分證正面照片";
      checked = false;
    }
    if (!id_back_img) {
      message += "身分證背面照片 ";
      checked = false;
    }
    if (!bank_code) {
      message += "銀行代碼 ";
      checked = false;
    }
    if (!bank_account) {
      message += "銀行帳號 ";
      checked = false;
    }
    if (!name) {
      message += "姓名 ";
      checked = false;
    }
    if (!id_number) {
      message += "身分證字號 ";
      checked = false;
    }
    if (!bank_account_img) {
      message += "銀行存摺照片";
      checked = false;
    }
    if (!checked) showMessage({ message, type: "warning", duration: 5000 });

    return checked;
  }

  return {
    //data
    kycAuth,
    kycVerifies,
    kycAuthForm,
    kycStatus,
    canUploadBankAccountImg,
    canUploadIdCardImg,
    //methods
    initKyc,
    submitKyc,
    setBankAccount,
    setBankAccountImg,
    setIdFrontImg,
    setIdBackImg,
    setIdNameAndNumber,
    setCanUploadBankAccountImg,
    setCanUploadIdCardImg
  };
}
